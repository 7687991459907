<template>
  <div v-if="partner">
    <partnership
      :title="partner.name"
      :image="partner.image"
      :content="partner.description"
    ></partnership>
  </div>
</template>

<script>
import Partnership from '../components/Partnership.vue'
import partnership from '../data/partnership.json'

export default {
  name: 'PartnershipDetail',
  components: {
    Partnership,
  },
  data() {
    return {
      partnership: partnership,
    }
  },
  computed: {
    partner() {
      return this.partnership.find(
        (partner) => partner.slug === this.$route.params.slug
      )
    },
  },
}
</script>
