<template>
  <b-container>
    <b-row class="my-4 mx-1">
      <b-col md="12" class="partnership">
        <h2>{{ title }}</h2>

        <b-img
          v-if="image"
          :src="imagePath"
          :alt="title"
          width="300"
          class="logo"
        ></b-img>

        <b-img
          v-else
          blank
          blank-color="#ccc"
          width="300"
          height="200"
          class="logo"
        ></b-img>

        <div class="text-justify" v-html="content"></div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'Partnership',
  props: {
    title: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
  computed: {
    imagePath() {
      return require(`@/assets/images/partner/${this.image}`)
    },
  },
}
</script>
